import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  linkButton: {
    color: "#493AB1",
    "& svg": {
      marginRight: "6px",
    },
    cursor: "pointer",
  },
}));
