import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import CloseIcon from "@material-ui/icons/CloseRounded";

import {
  MobileModal,
  Modal,
  Shimmer,
} from "@my-scoot/component-library-legacy";
import constants from "constants/constants";
import * as React from "react";
import { is_empty } from "utils/validations";

const useStyles = makeStyles((theme) => ({
  summaryInfoRoot: {
    paddingLeft: ({ isDesktop }) => (isDesktop ? "24px" : "16px"),
    "& th, td": {
      fontSize: "16px",
      padding: "10px 24px 10px 0",
      borderBottom: `1px solid ${theme?.palette?.secondary?.shade_100}`,
      color: theme?.palette?.basic?.black,
      opacity: 0.85,
    },
  },
  modalRoot: {
    minWidth: "560px",
    minHeight: "259px",
  },
  modalTitle: {
    fontSize: "18px",
    fontWeight: 700,
    "& span": {
      marginLeft: "12.25px",
    },
    display: "flex",
    alignItems: "center",
  },
  customHeaderRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "142.46px",
    padding: "16px 29.93px 16px 24.49px",
    borderBottom: `1.5px solid ${theme?.palette?.secondary?.shade_100}`,
    boxSizing: "border-box",
  },
  closeIcon: {
    cursor: "pointer",
  },
}));

const TrackingSummaryModal = ({
  open,
  onClose,
  onClickClose,
  affiliateData,
}) => {
  const isDesktop = window.screen.width > constants.mobile_width;
  const classes = useStyles({ isDesktop });

  let SummaryInfo = null;

  if (is_empty(affiliateData))
    SummaryInfo = (
      <div className={classes.summaryInfoRoot}>
        <TableContainer>
          <Table aria-label="Discout offering">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Shimmer
                    width="198px"
                    height="22.375px"
                    borderRadius="12px"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Shimmer
                    width="136px"
                    height="22.375px"
                    borderRadius="12px"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Shimmer
                    width="198px"
                    height="22.375px"
                    borderRadius="12px"
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Shimmer width="46px" height="22.375px" borderRadius="12px" />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  else
    SummaryInfo = (
      <div className={classes.summaryInfoRoot}>
        <TableContainer>
          <Table aria-label="Discout offering">
            <TableBody>
              <TableRow>
                <TableCell>Source</TableCell>{" "}
                <TableCell align="right">{affiliateData.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Views</TableCell>{" "}
                <TableCell align="right">{affiliateData.view}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Bookings</TableCell>{" "}
                <TableCell align="right">{affiliateData.bookings}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Total Leads</TableCell>{" "}
                <TableCell align="right">{affiliateData.leads}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );

  return isDesktop ? (
    <Modal
      modalPaperClassName={classes.modalRoot}
      open={open}
      onClose={onClose}
      customHeader={
        <div className={classes.customHeaderRoot}>
          {is_empty(affiliateData) ? (
            <Shimmer width="160.31px" height="28.8px" borderRadius="12px" />
          ) : (
            <span className={classes.modalTitle}>Tracking Summary</span>
          )}
          <CloseIcon className={classes.closeIcon} onClick={onClickClose} />
        </div>
      }
      customFooter={<></>}
    >
      {SummaryInfo}
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      header={
        <div>
          {is_empty(affiliateData) ? (
            <Shimmer width="160.31px" height="28.8px" borderRadius="12px" />
          ) : (
            <span className={classes.modalTitle}>Tracking Summary</span>
          )}
        </div>
      }
      customFooter={<></>}
    >
      {SummaryInfo}
    </MobileModal>
  );
};

export default TrackingSummaryModal;
