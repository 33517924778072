import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  linkField: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& a": {
      color: "#0052CC",
    },
  },
  mobileLinkFieldRoot: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  mobileLinkField: {
    maxWidth: "142px",
  },
  trackValue: {
    color: "#0052CC",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  shareIcon: {
    color: "#493AB1",
    padding: 0,
  },
  primaryActionField: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    borderBottom: "1.64px solid #E7E6E4",
    paddingBottom: "14px",
    marginTop: "12px",
    fontSize: "14px",
    "& :nth-child(1)": {
      paddingLeft: "13.27px",
      fontWeight: 500,
    },
    "& :nth-child(2)": {
      fontWeight: 300,
      paddingRight: "29.42px",
    },
  },
}));
