import React from "react";
// ICONS
import DomainLinkingIcon from "assets/images/domainLinking.svg";
import CustomCodeIcon from "assets/vectors/code.svg";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import { facebook_manager_docs } from "features/Integration/modules/WhatsappIntegrationSuccessView/constants/WhatsappIntegrationSuccessView.constants";
import { handleOpenNewWindow } from "utils/Utils";
import styles from "./Style.module.css";

// IDS (Todo combine these as on enum object later)
const domain_linking = "DomainLinking";
const custom_code = "custom_code";
// Comming Soon
const whatsapp = "WhatsApp";

export const gcSessionStorage = "integrations_gc_initiated";

export const integrationsCards = {
  [domain_linking]: {
    id: domain_linking,
    title: "Domain Linking",
    description: "Link your domain for a seemless experience across platforms",
    name: "domainName",
    image: DomainLinkingIcon,
    syncText: "Linked",
    learn_more: "Link your domain",
    learn_more_href:
      "https://support.exlyapp.com/support/solutions/articles/84000354964-set-up-a-custom-domain-name-on-exly",
  },
  [custom_code]: {
    id: custom_code,
    title: "Custom code",
    description: "Add custom code snippets to the head or body of your site.",
    image: CustomCodeIcon,
    syncText: "Integrated",
    learn_more: "Add Custom Code",
    locked: true,
    learn_more_href:
      "https://support.exlyapp.com/en/support/solutions/articles/84000392236-integrate-custom-code-with-exly",
  },
};

export const integration_tab_config = {
  guide: { key: "Guide", value: 1 },
  setup: { key: "Setup", value: 2 },
};

export const prev_tab_key = "prev_active_tab";

export const constants = {
  ids: {
    domain_linking: domain_linking,
    custom_code: custom_code,
  },
  cards_conf: Object.keys(integrationsCards).map(
    (key) => integrationsCards[key]
  ),
  creator_specific_integrations: [whatsapp],
  guide_conf: {
    [domain_linking]: {
      tab1: [
        {
          guideTitle: (
            <div className="mt-md-3 mt-lg-5">How do you link your domain?</div>
          ),
          guideContent: [
            { value: "Go to the Setup tab" },
            {
              value: (
                <>
                  Enter the domain you want to connect in the field provided at
                  the top-right and hit <b>Next</b>
                </>
              ),
            },
            { value: "Go and Sign in to your domain provider " },
            { value: "Go to DNS settings for the provider" },
            {
              value:
                "Replace the default name servers with the following ones: ",
              link: {
                value: "click here to watch tutorial for assistance",
                href: "https://www.youtube.com/watch?v=nlgDmH7bhpE&themeRefresh=1",
              },
            },
            {
              value: [
                "1. albert.ns.cloudflare.com",
                "2. alina.ns.cloudflare.com",
              ],
              type: "copy",
              // Have commented this intentionally, waiting for confirmation on this
              // disabled: true,
            },
            {
              value: (
                <>
                  Return to this page and click on the <b>Verify</b> button. It
                  verifies if the replacement of the name servers has been done
                  successfully and displays the message{" "}
                  <i>Name Servers Verified</i>, upon successful verification.
                </>
              ),
            },
            {
              value: (
                <>
                  Once verified, click on <b>Link Domain</b> button to initiate
                  domain linking
                </>
              ),
            },
            {
              value:
                "Domain will be linked successfully. Please note, it might take up to 24 hours for the same to reflect on the system.",
            },
          ],
          installationCtaText: "Link Domain",
        },
      ],
      tab2: {
        getSteps: [
          <>
            Enter the domain you want to connect in the field provided and{" "}
            <b>hit Next</b>
          </>,
          <>
            Sign in to your domain provider and go to DNS settings for the
            provider <br /> Replace the default name servers with the following
            ones:{" "}
            <a
              href="https://www.youtube.com/watch?v=nlgDmH7bhpE&themeRefresh=1"
              target="_blank"
              rel="noreferrer noopener"
            >
              click here to watch tutorial for assistance
            </a>
          </>,
          <>
            Click on the <b>Verify</b> button. It verifies if the replacement of
            the name servers has been done successfully and displays the message
            <i className="text-success"> Name Servers Verified</i>, upon
            successful verification.
          </>,
          <>
            Once verified, click on <b>Link Domain</b> button to initiate domain
            linking
          </>,
          "Domain linked initiated successfully. Please note, it might take up to 24 hours for the domain to link and reflect on the system.",
        ],
      },
    },
  },
  layoutTypes: {
    header: {
      empty_layout: "EMPTY_LAYOUT",
      button_layout: "BUTTON_LAYOUT",
      input_layout: "INPUT_LAYOUT",
      double_input: "DOUBLE_INPUT",
      copy_layout: "COPY_LAYOUT",
    },
    body: {
      simple_guide: "SIMPLE_GUIDE",
      tabs_guide: "TABS_GUIDE",
      tabs_with_checkpoints: "TABS_WITH_CHECKPOINTS",
    },
  },
  domainSteps: {
    addDomain: 0,
    nameServerStep: 1,
    verifyNameServers: 2,
    linkDomain: 3,
    domainLinkConfirmation: 4,
    domainLinkingCompleted: 5,
  },
  green_tick_application_guide_consts: [
    {
      icon: <LanguageOutlinedIcon className={styles.guideIconStyle} />,
      text: "Make sure you submit the form in English",
    },
    {
      icon: <QueryBuilderOutlinedIcon className={styles.guideIconStyle} />,
      text: (
        <>
          Allow a processing time of up to 10 days for your application.
          <br /> You will receive a response at your email
        </>
      ),
    },
    {
      icon: <DescriptionOutlinedIcon className={styles.guideIconStyle} />,
      text: "Please read the official document completely prior to the application",
      link: "here",
      onLinkClick: () => handleOpenNewWindow(facebook_manager_docs),
    },
  ],
};

export const INTEGRATIONS_LEARN_MORE_HREFS = {
  INTEGRATIONS: "https://support.exlyapp.com/support/solutions/84000149953",
};
