import { app_route_ids, app_route_keys } from "constants/urlPaths";
import { appendUrlParams, getRAUrlParams } from "utils/urlUtils";
import {
  constants,
  integration_tab_config,
  integrationsCards,
  prev_tab_key,
} from "./integrations.constants";
import { getSessionStorageItem } from "utils/getterAndSetters";
import SessionStorageConstants from "constants/SessionStorage.constants";
import { LOCKED_KEY } from "features/CustomCode/CustomCode.constants";

export const handleIntegrationsBackNavigation = ({
  history,
  id,
  isIntegrated = false,
}) => {
  const urlParams = getRAUrlParams();
  const prevActiveTab = urlParams.get(prev_tab_key);
  const previousRoute = getSessionStorageItem(
    SessionStorageConstants.ROUTE_COMING_FROM
  );

  if (parseInt(prevActiveTab) === integration_tab_config.setup.value) {
    let pathName = `${
      app_route_ids[app_route_keys.integrations]
    }/${id}/${isIntegrated}`;
    const urlParams = {
      prev_active_tab: integration_tab_config.guide.value,
    };
    if (
      [
        constants.ids.zapier,
        constants.ids.shiprocket,
        constants.ids.whatsapp,
      ].includes(id)
    ) {
      pathName = `${app_route_ids[app_route_keys.integrations]}/${id}`;
    }
    history.push(appendUrlParams(pathName, urlParams));
  } else if (
    parseInt(prevActiveTab) === integration_tab_config.guide.value &&
    previousRoute
  ) {
    window.location.href = previousRoute;
  } else {
    history.push(app_route_ids[app_route_keys.integrations]);
  }
};

/**
 * get config of a particular integration
 * @param {*} key key of the inegration
 * @param {*} planDetails active plan details containing integration object, to check if integration is unlocked for the creator or not
 * @returns
 */
export const getIntegrationConfig = ({ key, planDetails }) => {
  /* users/creator/plan/details response => "custom_code": { "custom_code": true } thats why [key][key]
     TODO: get this api repsonse updated
  */
  let integrationData = { ...integrationsCards[key] };
  if (LOCKED_KEY in integrationData && key in planDetails) {
    integrationData[LOCKED_KEY] = !planDetails?.[key]?.[key]; // response coming from backend
  }
  return integrationData;
};
