import React from "react";
import { ActionField } from "./components/CustomColumns";

export const columnConfig = [
  {
    field: "referral_coupon",
    headerName: "Referral Coupon",
    noWrap: true,
    sortable: false,
  },
  {
    field: "referrals",
    headerName: "No. of Referrals",
    noWrap: true,
    sortable: false,
  },
];

export const filterConfig = [
  {
    source: "referral_coupon",
    placeholder: "Offer name",
    type: "input",
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "referral_coupon__icontains":
      return `Offer Name: ${value}`;
    default:
      return undefined;
  }
};
