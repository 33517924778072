import { SALES_PAGE_STATUS } from "ui/pages/SalesPages/constants";

const TRAFFIC_LINK_REDIRECT_TYPE_ENUM = {
  HOME_PAGE: 1,
  LISTING: 2,
  SALES_PAGE: 3,
  PLAN: 4,
};

export const TRAFFIC_LINK_REDIRECT_TYPE = {
  HOME_PAGE: {
    value: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.HOME_PAGE,
    id: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.HOME_PAGE,
    label: "Home Page",
    key: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.HOME_PAGE,
  },
  LISTING: {
    value: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.LISTING,
    id: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.LISTING,
    label: "Offering",
    key: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.LISTING,
  },
  SALES_PAGE: {
    value: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.SALES_PAGE,
    id: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.SALES_PAGE,
    label: "Sales Page",
    key: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.SALES_PAGE,
  },
  PLAN: {
    value: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.PLAN,
    id: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.PLAN,
    label: "Plan",
    key: TRAFFIC_LINK_REDIRECT_TYPE_ENUM.PLAN,
  },
};

export const SALES_PAGE_STATUSES_TO_SHOW = [
  SALES_PAGE_STATUS.LIVE,
  SALES_PAGE_STATUS.SOLD_OUT,
];

export const TRAFFIC_ANALYSIS_LEARN_MORE_HREFS = {
  TRAFFIC_ANALYSIS:
    "https://support.exlyapp.com/support/solutions/articles/84000372465-traffic-analysis-with-exly",
  TRAFFIC_ANALYSIS_VIDEO: "https://www.youtube-nocookie.com/embed/IgW9FtMWS24",
};
