import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";

import StatsCard from "common/Components/StatsCard";
import constants from "constants/constants";
import dataProvider from "data/dataProvider";
import React, { useEffect, useState } from "react";
import classnames from "classnames";

// Icons
import VisibilityIcon from "@material-ui/icons/Visibility";
import LeadsIcon from "assets/images/trafficAnalysisLeads.svg";
import TotalBookingIcon from "assets/images/trafficAnalysisTotalBookings.svg";

const useStyles = makeStyles(() => ({
  statsWrapper: ({ isDesktop }) =>
    isDesktop
      ? {
          display: "flex",
          marginBottom: "20px",
          gap: "26px",
        }
      : {
          display: "flex",
          gap: "15px",
          width: "calc(100vw - 32px)",
          overflowX: "scroll",
        },
  statsCard: ({ isDesktop }) =>
    isDesktop
      ? {}
      : {
          marginBottom: "16px",
        },
  visibilityIcon: {
    fontSize: "32px",
  },
}));

const StatsLoadingState = ({ color }) => (
  <CircularProgress size={18} color={color} />
);

const TrafficAnalysisStats = () => {
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const classes = useStyles({ isDesktop });
  const [performanceData, setperformanceData] = useState("");

  useEffect(() => {
    (async () => {
      const statisticsData = await dataProvider.custom_request(
        "host/affliate/statistics",
        "GET"
      );
      if (statisticsData.status === 200) {
        setperformanceData(statisticsData.data);
      }
    })();
  }, []);

  return (
    <Box className={classes.statsWrapper}>
      <StatsCard
        className={classes.statsCard}
        color="primary"
        linkText=""
        title={constants.leads}
        tooltip="Number of people who showed interest in your webpage or offering pages."
        statsNumber={
          performanceData ? (
            performanceData?.all_leads
          ) : (
            <StatsLoadingState color="primary" />
          )
        }
        Icon={(props) => <img src={LeadsIcon} {...props} />}
      />
      <StatsCard
        className={classes.statsCard}
        color="fine_pine"
        linkText=""
        title={constants.total_bookings}
        tooltip="Number of bookings received via all tracking links"
        statsNumber={
          performanceData ? (
            performanceData?.all_bookings
          ) : (
            <StatsLoadingState color="fine_pine" />
          )
        }
        Icon={(props) => <img src={TotalBookingIcon} {...props} />}
      />
      <StatsCard
        className={classes.statsCard}
        color="persian_blue"
        linkText=""
        title={constants.views}
        tooltip="Number of people who visited your webpage or offering pages"
        statsNumber={
          performanceData ? (
            performanceData?.all_view
          ) : (
            <StatsLoadingState color="persian_blue" />
          )
        }
        Icon={({ className }) => (
          <VisibilityIcon
            className={classnames(classes.visibilityIcon, className)}
          />
        )}
      />
    </Box>
  );
};

export default TrafficAnalysisStats;
