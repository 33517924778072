import IconButton from "@material-ui/core/IconButton";
import React from "react";
import { useStyles } from "./CustomColumns.styles";
import ShareIcon from "@material-ui/icons/Share";
import classnames from "classnames";

export const LinkField = ({ record, source }) => {
  const classes = useStyles();
  return (
    <div className={classes.linkField}>
      <a target="_blank" rel="noopener noreferrer" href={record[source]}>
        {record[source]}
      </a>
    </div>
  );
};

export const MobileLinkField = ({ showShareIcon = true, record, source, onShareClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.mobileLinkFieldRoot}>
      <div className={classnames(classes.linkField, classes.mobileLinkField)}>
        <a target="_blank" rel="noopener noreferrer" href={record[source]}>
          {record[source]}
        </a>
      </div>
      {showShareIcon && (
        <IconButton
          className={classes.shareIcon}
          onClick={() => onShareClick(record)}
        >
          <ShareIcon />
        </IconButton>
      )}
    </div>
  );
};

export const ActionField = ({ record, onClick, label }) => {
  const classes = useStyles();
  return (
    <div onClick={() => onClick(record)} className={classes.trackValue}>
      {label}
    </div>
  );
};

export const ShareField = ({ record, onClick }) => {
  const classes = useStyles();
  return (
    <IconButton className={classes.shareIcon} onClick={() => onClick(record)}>
      <ShareIcon />
    </IconButton>
  );
};

export const PrimaryActionField = ({ record, label, source, onClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.primaryActionField} onClick={onClick}>
        <span>{label}:</span>
      <span>{record[source]}</span>
    </div>
  );
};
