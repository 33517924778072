import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {
  DesktopTable,
  MobileModal,
  MobileTable,
  Modal,
} from "@my-scoot/component-library-legacy";
import api from "data/APIs";
import dataProvider from "data/dataProvider";
import React, { useEffect, useState } from "react";
import MoneyField from "ui/modules/MoneyField";
import { useNotifications } from "utils/hooks";

const useStyles = makeStyles((theme) => ({
  modalContent: {
    padding: "24px",
  },
  mobileTableWrapper: {
    padding: "16px",
  },
}));

const UserReferralDetailsModal = ({ open, onClose, coupon }) => {
  const classes = useStyles();
  const isDesktop = useMediaQuery("(min-width: 767px)");
  const [apiData, setApiData] = useState([]);
  const { pushNotification } = useNotifications();

  useEffect(() => {
    setApiData([]);
    (async () => {
      try {
        const response = await dataProvider.custom_request(
          api.user_referral_details,
          "GET",
          { coupon }
        );
        const { status, data, message } = response;
        if (status === 200) {
          setApiData(data);
        }
      } catch (error) {
        setApiData([]);
        onClose();
        pushNotification("An error occurred.", { variant: "outlined", color: "coral_red" });
      }
    })();
  }, [coupon]);

  const columnConfig = [
    {
      field: "referred_email",
      headerName: "Referred User",
      noWrap: true,
      sortable: false,
    },
    {
      field: "",
      headerName: "Price Paid",
      valueFormatter: (record) => (
        <MoneyField record={record} source="creator_sales" noRightMargin />
      ),
      noWrap: true,
      sortable: false,
    },
    {
      field: "listing_name",
      headerName: "Offering Name",
      sortable: false,
    },
  ];

  return isDesktop ? (
    <Modal
      dialogContentClassName={classes.modalContent}
      title="Referral Details"
      open={open}
      onClose={onClose}
      customFooter={<></>}
    >
      <DesktopTable columns={columnConfig} rows={apiData || []} />
    </Modal>
  ) : (
    <MobileModal
      open={open}
      onClose={onClose}
      header="Referral Details"
      customFooter={<></>}
    >
      <MobileTable
        listingWrapperClassName={classes.mobileTableWrapper}
        columns={columnConfig}
        rows={apiData || []}
        borderedFields
        fieldsLeftPadded
        fieldsAlignment="space-between"
        drawerFieldsBordered
        drawerFieldsAlignment="space-between"
      />
    </MobileModal>
  );
};

export default UserReferralDetailsModal;
