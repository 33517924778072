import React from "react";
import { useStyles } from "./CustomColumns.styles";
import VisibilityIcon from "@material-ui/icons/Visibility";

export const ActionField = ({ record, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.linkButton} onClick={() => onClick(record)}>
      <VisibilityIcon />
      <span>View</span>
    </div>
  );
};
