import {
  LinkField,
  ActionField,
  MobileLinkField,
  ShareField,
} from "./components/CustomColumns/CustomColumns";
import React from "react";

export const columnConfig = (
  handleTrackingSummaryClick,
  handleViewBookingsClick,
  handleShare
) => [
  {
    isDrawerTitle: true,
    renderColumn: (record) => record?.name,
    hidden: true,
    hiddenDesktopColumn: true,
  },
  {
    field: "name",
    headerName: "Source Name",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "target",
    headerName: "Link directs to",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
    ellipsis: true,
    maxCharCount: 30,
  },
  {
    field: "url",
    headerName: "URL",
    sortable: false,
    emptyField: "N/A",
    width: "188px",
    hidden: true,
    renderCell: (record) => <LinkField record={record} source="url" />,
  },
  {
    field: "leads",
    headerName: "Leads",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
  },
  {
    field: "bookings",
    headerName: "Total Bookings",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
  },
  {
    headerName: "URL",
    hiddenDesktopColumn: true,
    isCollapsed: true,
    valueFormatter: (record) => (
      <MobileLinkField record={record} source="url" showShareIcon={false} />
    ),
  },
  {
    field: "tracking_summary",
    headerName: "Tracking Summary",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
    isCollapsed: true,
    renderCell: (record) => (
      <ActionField
        record={record}
        onClick={handleTrackingSummaryClick}
        label="View Summary"
      />
    ),
    valueFormatter: (record) => (
      <ActionField
        record={record}
        onClick={handleTrackingSummaryClick}
        label="View Summary"
      />
    ),
  },
  {
    field: "",
    headerName: "View Bookings",
    noWrap: true,
    sortable: false,
    emptyField: "N/A",
    isCollapsed: true,
    renderCell: (record) => (
      <ActionField
        record={record}
        onClick={handleViewBookingsClick}
        label={record.bookings ? "View Bookings" : "N/A"}
      />
    ),
    valueFormatter: (record) => (
      <ActionField
        record={record}
        onClick={handleViewBookingsClick}
        label={record.bookings ? "View Bookings" : "N/A"}
      />
    ),
  },
  {
    field: "",
    headerName: "Actions",
    renderCell: (record) => (
      <ShareField record={record} onClick={() => handleShare(record, "url")} />
    ),
    hidden: true,
    sortable: false,
    align: 'center',
  },
];

export const filterConfig = [
  {
    source: "name",
    placeholder: "Source Name",
    type: "input",
  },
  {
    source: "target",
    placeholder: "Link directs to",
    type: "input",
  },
];

export const tableChipsFormatter = (filter, value) => {
  switch (filter) {
    case "name__icontains":
      return `Name: ${value}`;
    case "target__icontains":
      return `Link directs to: ${value}`; 
    default:
      return undefined;
  }
};
